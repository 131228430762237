import "styles/pages/page-shopping.scss";

import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import TextWithSymbol from "components/TextWithSymbol";
import Breadcrumbs from "../components/Breadcrumbs";

const ZakupyPage = ({ pageContext }) => {
	const pageShopping = pageContext.pageContent;
	return (
		<Layout headerColor="#6AD044">
			<Seo
				title={
					pageContext.seo.title
						? pageContext.seo.title
						: pageContext.title
				}
				description={
					pageContext.seo.metaDesc && pageContext.seo.metaDesc
				}
			/>
			<SubpageHeader
				image={require("assets/images/zakupy-header-bg.png").default}
			/>
			<Breadcrumbs currentLocationLabel="Zakupy" />
			<section className="shopping">
				<div className="container">
					<h2 className="section-title">
						{pageShopping.shoppingTitle}
					</h2>
					<div className="row">
						<div className="col-xl-5 offset-xl-1">
							<div
								className="shopping__text"
								dangerouslySetInnerHTML={{
									__html: pageShopping.shoppingDesc,
								}}
							/>
							<div className="shopping__city-container">
								{pageShopping?.tradeDistricts?.map(
									(node, index) => {
										return (
											<TextWithSymbol
												title={node.title}
												key={index}
											/>
										);
									}
								)}
							</div>
							<div className="shopping__capital-desc">
								{pageShopping.shoppingSubdesc}
							</div>
						</div>
						<div className="col-xl-5 offset-xl-1">
							<img
								src={
									require("assets/images/shops-img-1.jpg")
										.default
								}
								alt=""
								className="img-fluid shopping__img"
							/>
						</div>
					</div>
				</div>
			</section>

			<section className="fair">
				<div className="container">
					<div className="row flex-lg-row flex-column-reverse">
						<div className="col-lg-5 offset-lg-1 text-center">
							<img
								src={
									require("assets/images/targ-img-1.png")
										.default
								}
								alt=""
								className="img-fluid "
							/>
						</div>
						<div className="col-xl-5 col-lg-6 offset-xl-1">
							<h2 className="section-title">
								{pageShopping.fairTitle}
							</h2>
							<div className="fair__text-container">
								<div
									className="fair__text"
									dangerouslySetInnerHTML={{
										__html: pageShopping.fairDesc,
									}}
								></div>
								{pageShopping.fairCategories.map(
									(node, index) => {
										return (
											<TextWithSymbol
												title={node.title}
												desc={node.desc}
												key={index}
											/>
										);
									}
								)}
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="swimming-fair">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-xl-6 col-lg-5">
							<div className="swimming-fair__inner">
								<h2 className="section-title">
									{pageShopping.swimmingFairTitle}
								</h2>
								<div
									className="swimming-fair__text"
									dangerouslySetInnerHTML={{
										__html: pageShopping.swimmingFairDesc,
									}}
								></div>
							</div>
						</div>
						<div className="col-xl-5 offset-xl-1 col-lg-6 swimming-fair__col-1">
							<img
								src={pageShopping.swimmingFairImg1?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
							<div className="row">
								<div className="col-md-6">
									<img
										src={
											pageShopping.swimmingFairImg2
												?.sourceUrl
										}
										alt=""
										className="img-fluid swimming-fair__img-1"
									/>
								</div>
								<div className="col-md-6">
									<img
										src={
											pageShopping.swimmingFairImg3
												?.sourceUrl
										}
										alt=""
										className="img-fluid swimming-fair__img-2"
									/>
								</div>
							</div>
							<img
								src={pageShopping.swimmingFairImg4?.sourceUrl}
								alt=""
								className="img-fluid swimming-fair__img-3"
							/>
						</div>
					</div>
				</div>
			</section>

			<section className="night-fair">
				<div className="container">
					<div className="row flex-lg-row flex-column-reverse align-items-center">
						<div className="col-lg-5 offset-lg-1 text-center">
							<img
								src={pageShopping.nightFairImg?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
						<div className="col-lg-5 offset-xl-1">
							<div className="night-fair__inner">
								<h2 className="section-title">
									{pageShopping.nightFairTitle}
								</h2>
								<div
									className="night-fair__text"
									dangerouslySetInnerHTML={{
										__html: pageShopping.nightFairDesc,
									}}
								></div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="weekend-fair">
				<div className="container">
					<div className="row flex-lg-row flex-column-reverse align-items-center">
						<div className="col-lg-6">
							<div className="weekend-fair__inner">
								<h2
									className="section-title section-title--white"
									dangerouslySetInnerHTML={{
										__html: pageShopping.weekendFairTitle,
									}}
								></h2>
								<div
									className="weekend-fair__text"
									dangerouslySetInnerHTML={{
										__html: pageShopping.weekendFairDesc,
									}}
								></div>
							</div>
						</div>
						<div className="col-lg-5 offset-lg-1 text-center">
							<img
								src={pageShopping.weekendFairImg?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
					</div>
				</div>
			</section>

			<section className="rom-fair">
				<div className="container">
					<div className="row flex-lg-row flex-column-reverse align-items-center">
						<div className="col-lg-6">
							<div className="rom-fair__inner">
								<h2 className="section-title">
									{pageShopping.hubFairTitle}
								</h2>
								<div
									className="rom-fair__text"
									dangerouslySetInnerHTML={{
										__html: pageShopping.hubFairDesc,
									}}
								></div>
							</div>
						</div>
						<div className="col-lg-5  offset-lg-1 text-center">
							<img
								src={pageShopping.hubFairImage?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
					</div>
				</div>
			</section>

			<section className="riverfront">
				<div className="container">
					<div className="row">
						<div className="col-lg-5 offset-lg-1 text-center">
							<img
								src={pageShopping.riverfrontImg?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
						<div className="col-lg-6 col-xl-5 offset-xl-1">
							<h2 className="section-title">
								{pageShopping.riverfrontTitle}
							</h2>
							<div
								className="riverfront__text"
								dangerouslySetInnerHTML={{
									__html: pageShopping.riverfrontDesc,
								}}
							></div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default ZakupyPage;
